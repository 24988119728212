import '../styles/Projects.scss';
import Project1 from '../assets/Portfolio-Project1.gif';
import Project2 from '../assets/Portfolio-Project2.png';
import Project3 from '../assets/Portfolio-Project3.png';
import Project4 from '../assets/Portfolio-Project4.png';
import SingleProject from './SingleProject';

export default function Projects({ dark }) {
  return (
    <div className={'section' + (dark ? ' section-dark' : '')}>
      <div className='container' id='projects'>
        <div className='projects-container'>
          <h1 className='projects-title'>PROJECTS</h1>

          <SingleProject
            thumbnail={Project4}
            alt={'project-4-thumbnail'}
            thumbnailClassName={'project-4-thumbnail'}
            title={'RED PACKET CLICKER // JAN 2023'}
            shortDescription={'Lunar New Year Clicker Game'}
            stack={'Django, React, CSS'}
            longDescription={'One week solo project'}
            link={'https://red-packet-clicker.netlify.app/'}
            repo={
              'https://github.com/siuusunn/ga-project-4-red-packet-clicker-client'
            }
          />
          <SingleProject
            thumbnail={Project3}
            alt={'project-3-thumbnail'}
            thumbnailClassName={'project-3-thumbnail'}
            title={'THE FORUM // JAN 2023'}
            shortDescription={'Revamped Discussion Board'}
            stack={'MongoDb, Express, React, Node, MUI'}
            longDescription={'One week group project of 3'}
            link={'https://alicelo-theforum.netlify.app/'}
            repo={'https://github.com/siuusunn/ga-project-3-the-forum-client'}
          />
          <SingleProject
            thumbnail={Project2}
            alt={'project-2-thumbnail'}
            thumbnailClassName={'project-2-thumbnail'}
            title={'POKEDEX // DEC 2022'}
            shortDescription={'Pokemon Directory'}
            stack={'HTML, CSS, Sass, React, Bulma'}
            longDescription={'48-hours Pair Coding React project'}
            link={'https://alicelo-pokedex.netlify.app/'}
            repo={'https://github.com/siuusunn/ga-project-2-pokedex'}
          />
          <SingleProject
            thumbnail={Project1}
            alt={'project-1-thumbnail'}
            thumbnailClassName={'project-1-thumbnail'}
            title={'SNAKE // NOV 2022'}
            shortDescription={'Homage to Nokia 8210 Snake Game'}
            stack={'HTML, CSS, JavaScript'}
            longDescription={'One week solo project'}
            link={'https://siuusunn.github.io/ga-project-1-snake-game/'}
            repo={'https://github.com/siuusunn/ga-project-1-snake-game'}
          />
        </div>
      </div>
    </div>
  );
}

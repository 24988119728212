import '../src/styles/App.scss';
import Navbar from './components/Navbar';
import NavbarMobile from './components/NavbarMobile';
import NameAndTitle from './components/common/NameAndTitle';
import NameAndTitleMobile from './components/common/NameAndTitleMobile';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import backgroundvideo from '../src/assets/bg-video2.mp4';

function App() {
  return (
    <>
      <video autoPlay muted loop id='bg-video'>
        <source src={backgroundvideo} type='video/mp4'></source>
      </video>
      <div className='App bg'>
        <div className='nav-and-title-container'>
          <NameAndTitleMobile />
          <NavbarMobile />
          <Navbar />
          <NameAndTitle />
        </div>
        <div className='sections-container'>
          <Home dark={true} />
          <About dark={true} />
          <Projects dark={true} />
          <Experience dark={true} />
          <Contact dark={true} />
        </div>
      </div>
    </>
  );
}

export default App;

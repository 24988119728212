import '../styles/Navbar.scss';
import { Link } from 'react-scroll';

export default function Navbar() {
  return (
    <nav className='nav' id='navbar'>
      <div className='nav-content'>
        <ul className='nav-items'>
          <li className='nav-item home-button'>
            <Link
              activeClass='active'
              to='home'
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}
            >
              HOME
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              activeClass='active'
              to='about'
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}
            >
              ABOUT
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              activeClass='active'
              to='projects'
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}
            >
              PROJECTS
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              activeClass='active'
              to='experience'
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}
            >
              EXPERIENCE
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              activeClass='active'
              to='contact'
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

import '../../styles/NameAndTitleMobile.scss';

export default function NameAndTitleMobile() {
  return (
    <div className='name-and-title-mobile'>
      <h2 className='name-mobile'>ALICE LO</h2>
      <h4 className='title-mobile'>JUNIOR SOFTWARE ENGINEER</h4>
    </div>
  );
}

import warnerLogo from '../assets/WMG_Logo_Black.png';
import GALogo from '../assets/GA_Logo.png';
import NettwerkLogo from '../assets/Nettwerk_logo.png';
import '../styles/Experience.scss';

export default function Experience({ dark }) {
  return (
    <div className={'section' + (dark ? ' section-dark' : '')}>
      <div className='container' id='experience'>
        <div className='experience-container'>
          <h1 className='experience-title'>EXPERIENCE</h1>
          <div className='single-experience-container'>
            <div className='logo-title-container'>
              <img
                src={GALogo}
                alt='general-assembly'
                className='ga-logo'
              ></img>
              <div className='job-title-container'>
                <h3 className='company-name'>GENERAL ASSEMBLY LONDON</h3>
                <h5 className='job-title-date'>
                  Software Engineering Immersive // OCT22 - JAN23
                </h5>
              </div>
            </div>
            <div className='experience-content-container'>
              <ul>
                <li>
                  12 week immersive course to master industry-standard software
                  development skills and knowledge, where I built both frontend
                  and fullstack apps with individual and group projects that
                  required code-review, pair-coding and version control
                </li>
                <li>
                  Stack: HTML, CSS, Sass, JavaScript, Node, Express, React,
                  MongoDB, Python, Django, PostgreSQL, RESTful API, Git
                </li>
              </ul>
            </div>
          </div>
          <div className='single-experience-container'>
            <div className='logo-title-container'>
              <img
                src={NettwerkLogo}
                alt='nettwerk-music'
                className='nettwerk-logo'
              ></img>
              <div className='job-title-container'>
                <h3 className='company-name'>NETTWERK MUSIC UK</h3>
                <h5 className='job-title-date'>
                  Jr. Product Manager // OCT21 - OCT22
                </h5>
              </div>
            </div>
            <div className='experience-content-container'>
              <ul>
                <li>
                  Led all aspects of artist development across both internal and
                  external teams such as publicity, radio, digital for over 12
                  artists from the UK including Seafret and RHODES
                </li>
                <li>
                  Created international marketing campaigns and developed
                  value-added initiatives to support the release strategy
                </li>
                <li>
                  Acted as the primary communication liaison between artists/
                  managers, internal teams, media outlets, and streaming
                  partners for each project
                </li>
              </ul>
            </div>
          </div>
          <div className='single-experience-container'>
            <div className='logo-title-container'>
              <img
                src={warnerLogo}
                alt='warner-music'
                className='warner-logo'
              ></img>
              <div className='job-title-container'>
                <h3 className='company-name'>WARNER MUSIC ASIA</h3>
                <h5 className='job-title-date'>
                  Regional Marketing Executive // MAR15 - JUL18
                </h5>
              </div>
            </div>
            <div className='experience-content-container'>
              <ul>
                <li>
                  Led multi-country promo tours with media interviews and
                  performances in 9 countries in Asia for Charlie Puth, Dua Lipa
                  etc.
                </li>
                <li>
                  Organized an international press event in Los Angeles for over
                  20 media from Asia with frontline artists from Atlantic
                  Records including Charlie Puth and Panic! At The Disco
                </li>
                <li>
                  Revamped the weekly new music pitching process to DSP partners
                  which resulted in an increase of 20% growth in playlist
                  seeding
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import '../../styles/NameAndTitle.scss';

export default function NameAndTitle() {
  return (
    <div className='name-and-title'>
      <h1>ALICE LO</h1>
      <h3>JUNIOR SOFTWARE ENGINEER</h3>
    </div>
  );
}

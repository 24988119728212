import { MailOutlined, LinkedIn, GitHub } from '@mui/icons-material';
import '../styles/Contact.scss';

export default function Contact({ dark }) {
  return (
    <div className={'section' + (dark ? ' section-dark' : '')}>
      <div className='section-content container contact' id='contact'>
        <div className='contact-container'>
          <h1 className='contact-title'>CONTACT</h1>
          <div className='contact-content-container'>
            <div className='single-contact-container'>
              <MailOutlined />
              <a href='mailto:alicelo.dev@gmail.com'>alicelo.dev@gmail.com</a>
            </div>
            <div className='single-contact-container'>
              <GitHub />
              <a
                href='https://github.com/siuusunn/'
                target='_blank'
                rel='noreferrer'
              >
                siuusunn
              </a>
            </div>
            <div className='single-contact-container'>
              <LinkedIn />
              <a
                href='https://www.linkedin.com/in/aliceyylo/'
                target='_blank'
                rel='noreferrer'
              >
                aliceyylo
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

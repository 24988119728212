import '../styles/About.scss';
import profilePicture from '../assets/profile-picture.png';

export default function About({ dark }) {
  return (
    <div className={'section' + (dark ? ' section-dark' : '')}>
      <div className='container' id='about'>
        {/* section-content about-section */}
        <div className='about-container'>
          <h1>ABOUT</h1>

          <img
            src={profilePicture}
            alt='profile'
            className='profile-picture'
          ></img>
          <p className='personal-statement'>
            <i>
              "Do the best you can until you know better. Then when you know
              better, do better." Maya Angelou.
            </i>
          </p>
          <p className='personal-statement'>
            The drive to solve problems and strive for improvements have been
            instrumental in my whole career journey. The 5 years I have spent
            working in Marketing in the music industry have equipped me with
            communication and project management skills that I could transfer to
            different industries and apply in my first role as a developer.
          </p>
          <p className='personal-statement'>
            One of my core values is collaboration, this enabled me to build
            lasting relationships in my previous roles, and throughout my career
            journey. I look forward to leveraging my interpersonal skills to
            make a positive impact on my future team. "Be fierce but kind" is my
            work and life motto!
          </p>
          <p className='personal-statement'>
            My passion for coding began in the days of Myspace and Xanga, and it
            was reignited after taking free courses online. This inspired me to
            enroll in a Software Engineering course to dive deeper into the
            industry. I knew I have made the right decision when I often forgot
            to eat when I'm coding, that's a sign that I really enjoy what I'm
            doing.
          </p>

          <p className='personal-statement'>
            I am now looking for exciting opportunities where I can translate
            the skills I’ve gained and bring my passion for coding to work in an
            environment that shares similar values!
          </p>
          <p className='other-info'>
            <b>STACK</b>: HTML, CSS, Sass, JavaScript, Node, Express, React,
            MongoDB, Python, Django, PostgreSQL, RESTful API, Git
          </p>
          <p className='other-info'>
            <b>LANGUAGES</b>: English (Bilingual), Cantonese (Native), Mandarin
            (Bilingual), Spanish (Intermediate), German (Beginner)
          </p>
          <p className='other-info'>
            <b>OTHER SKILLS</b>: Project & Product Management, Event Planning,
            Digital Marketing
          </p>

          <p className='other-info'>
            <b>INTERESTS</b>: Video Games - All time favorite is Borderlands 2.
            Also played <i>A LOT</i> of League of Legends. Currently a Seer main
            who is hard stuck at Bronze I in Apex Legends.
            <br />
            Travelling - Japan and Italy are my go-to destinations for holidays,
            and I would love to visit Mongolia and Syria one day.
            <br />
            Cooking - I enjoy cooking all kinds of cuisine (I'm pretty good at
            making Cantonese and Japanese dishes) and hosting dinner parties
          </p>
        </div>
      </div>
    </div>
  );
}

import '../styles/Home.scss';

export default function Home({ dark }) {
  return (
    <div className={'section' + (dark ? ' section-dark' : '')}>
      <div className='section-content' id='home'>
        <></>
      </div>
    </div>
  );
}

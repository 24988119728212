export default function SingleProject({
  thumbnail,
  alt,
  thumbnailClassName,
  title,
  shortDescription,
  stack,
  longDescription,
  link,
  repo
}) {
  return (
    <div className='single-project-container hover'>
      <a href={link} target='_blank' rel='noreferrer'>
        <img src={thumbnail} alt={alt} className={thumbnailClassName}></img>
      </a>
      <div className='single-project-info'>
        <h4 className='single-project-title'>{title}</h4>
        <p className='single-project-short-description'>{shortDescription}</p>
        <p className='single-project-stack'>
          <i>{stack}</i>
        </p>
        <p className='single-project-long-description'>{longDescription}</p>
        <a href={link} target='_blank' rel='noreferrer'>
          LINK
        </a>
        <a href={repo} target='_blank' rel='noreferrer'>
          REPO
        </a>
      </div>
    </div>
  );
}
